// Load background images
jQuery('.load-bg-img').each(function(i, obj) {
  var large_img = jQuery(this).attr('data-bg-large');
  var small_img = jQuery(this).attr('data-bg-small');

  var item = jQuery(this);

  var bgImg = new Image();
  bgImg.onload = function() {
    item.css('background-image', 'url(' + bgImg.src + ')');
    item.addClass('loaded');
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = item.attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = item.attr('data-bg-small');
  } else {
    bgImg.src = item.attr('data-bg-tiny');
  }
});


// If we have a hero Image
if (jQuery('.page-title').length) {
  var bgImg = new Image();
  bgImg.onload = function() {
    jQuery('.page-title').css('background-image', 'url(' + bgImg.src + ')');
    jQuery('.page-title').addClass('inplace');
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = jQuery('.page-title').attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = jQuery('.page-title').attr('data-bg-small');
  } else {
    bgImg.src = jQuery('.page-title').attr('data-bg-tiny');
  }
}






/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        $('.nice-select-container select').addClass('wide');
        $('.nice-select-container select').niceSelect();

        // Make the shop select lists look nice.
        $('select#size').addClass('wide');
        $('select#size').niceSelect();

        // Select list for contact form
        //$('.gform_body select').addClass('wide');
        //$('.gform_body select').niceSelect();

        //
        // Flexslider background images
        //
        //setTimeout(function() {
        jQuery('.flexslider ul li').each(function(i, obj) {
          var large_img = jQuery(this).attr('data-bg-large');
          var small_img = jQuery(this).attr('data-bg-small');

          var list_item = $(this);

          if (jQuery(document).width() > 800) {
            $('<img src="' + large_img + '">').on('load', function() {
              list_item.css('background-image', 'url(' + large_img + ')');
              list_item.addClass('loaded');
            });

          } else {
            $('<img src="' + small_img + '">').on('load', function() {
              list_item.css('background-image', 'url(' + small_img + ')');
              list_item.addClass('loaded');
            });
          }
        });
        //}, 700);




        $('.marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p').each(function() {
          var string = $(this).html();
          string = string.replace(/ ([^ ]*)$/, '&nbsp;$1');
          $(this).html(string);
        });







        if (1) {
          var myElement = document.querySelector("header");
          // construct an instance of Headroom, passing the element
          var headroom = new Headroom(myElement, {
            tolerance: {
              down: 11,
              up: 3
            }
          });
          // initialise
          headroom.init();
        }



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.








var expanded_menu_id = false;
var mouse_over_status_array = [""];
var headerPosition = 'absolute';
var headerHidden = 60;
// Showing mobile version of secondary nav menu?
var mobile_nav_sc = false;


function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function hideDropdown(main_class, no_delay, delay_ms) {
  // Pause to make sure this is what the user wants
  if (!mouse_over_status_array[main_class] && !no_delay) {
    setTimeout("hideDropdown(\"" + main_class + "\", true)", delay_ms);
  }
  // Show the dropdown
  else if (!mouse_over_status_array[main_class]) {
    //jQuery("." + main_class + " > ul.sub-menu").hide();
    jQuery("." + main_class + " > ul.sub-menu").removeClass('hovered');
    setTimeout(function() {
      jQuery("." + main_class + " > ul.sub-menu").removeClass('inplace');
    }, 100);
    // Change the parent class
    jQuery("." + main_class).removeClass("hovered-submenu");
  }
}



function showDropdown(main_class, no_delay, delay_ms) {
  // Pause to make sure this is what the user wants
  if (mouse_over_status_array[main_class] && !no_delay) {
    setTimeout("showDropdown(\"" + main_class + "\", true)", delay_ms);
  }
  // Show the dropdown
  else if (mouse_over_status_array[main_class]) {
    //jQuery("." + main_class + " > ul.sub-menu").slideDown("fast");
    jQuery("." + main_class + " > ul.sub-menu").addClass('hovered');
    setTimeout(function() {
      jQuery("." + main_class + " > ul.sub-menu").addClass('inplace');
    }, 100);
    // Change the parent class
    jQuery("." + main_class).addClass("hovered-submenu");
  }
}

function getScrollTop() {
  if (typeof pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = (D.clientHeight) ? D : B;
    return D.scrollTop;
  }
}



function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}

// State of the header.
var top_stick = true;
var darker_nav = false;
// Update the navigation based on the width of the viewport.
function updateNav() {

  //
  // Update header background color.
  //
  var from_top = jQuery(window).scrollTop();
  if (from_top > 10 && !darker_nav) {
    jQuery(".header").addClass('scrollnav');
    darker_nav = true;
    console.log('DARK NAVB');
  } else if (from_top <= 10 && darker_nav) {
    jQuery(".header").removeClass('scrollnav');
    darker_nav = false;
    console.log('REMOVE DARK');
  }


  var doc_width = jQuery(window).width();
  var doc_height = jQuery(window).height();
  if (doc_width <= 1000) {
    //var from_top = jQuery(window).scrollTop();
    var myElement = document.querySelector("header");
    // construct an instance of Headroom, passing the element
    /*
    var headroom = new Headroom(myElement, {
      tolerance: {
        down: 9000,
        up: 9000
      }
    });
    // initialise
    headroom.init();
    */

    jQuery('.nav-primary ul li.dropdown > a').click(function(e) {
      e.preventDefault();
    });

  } else {
    //jQuery('.nav-primary ul li.dropdown > a').unbind('click');
  }
}




jQuery(document).ready(function() {
  jQuery('html').addClass('js');
  console.log(jQuery("html").attr("class"));


  // Remove 300ms delay when tapping on touch devices.
  jQuery(function() {
    FastClick.attach(document.body);
  });

  updateNav();

  jQuery(window).scroll(jQuery.throttle(65, updateNav));

  jQuery(window).on("debouncedresize", function(event) {
    // Your event handler code goes here.
    updateNav();
  });




  var doc_width = jQuery(window).width();


  if (doc_width > 1000) {
    jQuery("header nav li.menu-item-has-children").on("mouseleave", function(e) {
      // Get the menu ID.
      var parent_class = jQuery(this).attr('class');
      var pieces = parent_class.split(' ');
      var main_class = '';
      // Find the one that starts with "menu".
      for (var i = 0; i < pieces.length; i++) {
        if (pieces[i].search("menu-") === 0) {
          main_class = pieces[i];
        }
      }
      if (jQuery(document).width() > 1000) {
        // Remove hover class.
        jQuery(this).removeClass("hovered");
        // Make menu dissappear.
        mouse_over_status_array[main_class] = false;
        hideDropdown(main_class, false, "100");
      }
    });
    jQuery("header nav li.menu-item-has-children").on("mouseenter click", function(e) {
      // Get the menu ID.
      var parent_class = jQuery(this).attr('class');
      var pieces = parent_class.split(' ');
      var main_class = '';
      // Find the one that starts with "menu".
      for (var i = 0; i < pieces.length; i++) {
        if (pieces[i].search("menu-") === 0) {
          main_class = pieces[i];
        }
      }
      // If submenu exists.
      if (jQuery(document).width() > 1000 && !jQuery(this).hasClass('hovered')) {
        // Close the Otherwise
        // Add hover class.
        jQuery(this).addClass("hovered");
        // Show menu.
        mouse_over_status_array[main_class] = true;
        showDropdown(main_class, false, "100");
      }
    });
  }



  //
  // Deactivate dropdown links
  //
  if (doc_width <= 1000) {
    jQuery('header nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
    });


    jQuery("header nav li.menu-item-has-children").on("click", function(e) {
      // Get the menu ID.
      var parent_class = jQuery(this).attr('class');
      var pieces = parent_class.split(' ');
      var main_class = '';
      // Find the one that starts with "menu".
      for (var i = 0; i < pieces.length; i++) {
        if (pieces[i].search("menu-") === 0) {
          main_class = pieces[i];
        }
      }

      // If submenu exists.
      if (jQuery(document).width() > 1000 && !jQuery(this).hasClass('hovered')) {
        // Remove hover class.
        jQuery('header nav li.menu-item-has-children').removeClass("hovered");
        // Make menu dissappear.
        //hideDropdown(main_class, false, "200");
        jQuery("header nav li.menu-item-has-children > ul.sub-menu").hide();
        // Change the parent class
        jQuery('header nav li.menu-item-has-children').removeClass("hovered_submenu");
        // Close the Otherwise
        // Add hover class.
        jQuery(this).addClass("hovered");
        // Show menu.
        mouse_over_status_array[main_class] = true;
        showDropdown(main_class, false, "100");
      }
      // Has touch events
      else if (jQuery(document).width() > 1000 && jQuery(this).hasClass('hovered')) {
        // Remove hover class.
        jQuery(this).removeClass("hovered");
        jQuery(this).removeClass("hovered_submenu");
        // Make menu dissappear.
        mouse_over_status_array[main_class] = false;
        hideDropdown(main_class, false, "100");
      }
    });
  }

  /*
  jQuery(document).click(function(event) {
      if (jQuery(document).width() >= 1000 && Modernizr.touchevents) {
          if (!jQuery(event.target).closest('#nav').length &&
              !jQuery(event.target).is('#nav')
          ) {
            // Remove hover class.
            jQuery('header nav li.dropdown').removeClass("hovered");
            // Make menu dissappear.
            //hideDropdown(main_class, false, "200");
            jQuery("header nav li.dropdown > ul.sub-menu").hide();
            // Change the parent class
            jQuery('header nav li.dropdown').removeClass("hovered_submenu");
          }
      }
  });
  */



  jQuery("header nav li.menu-item-has-children > a").on("click", function(e) {
    // Get the menu ID.
    var parent_class = jQuery(this).parent().attr('class');
    var pieces = parent_class.split(' ');
    var main_class = '';
    // Find the one that starts with "menu".
    for (var i = 0; i < pieces.length; i++) {
      if (pieces[i].search("menu-") === 0) {
        main_class = pieces[i];
      }
    }

    // If submenu exists.
    if (jQuery(document).width() < 1000 && !jQuery(this).hasClass("hovered")) {
      // Add hover class.
      jQuery(this).addClass("hovered");
      // Show menu.
      mouse_over_status_array[main_class] = true;
      showDropdown(main_class, false, "0");
    } else if (jQuery(document).width() < 1000) {
      // Remove hover class.
      jQuery(this).removeClass("hovered");
      // Hide menu.
      mouse_over_status_array[main_class] = false;
      hideDropdown(main_class, false, "0");
    }
  });








  /* Show and hide the mobile menu. */
  jQuery("#mobile-menu").unbind("click").bind("click", function(e) {

    // Slide the mobile menu out.
    if (!jQuery(".wrapper").hasClass('menu-open')) {
      jQuery(".wrapper").addClass("menu-open");

      // Blur the menu
      setTimeout(function() {
        jQuery(".wrapper").addClass("with-transitions");
        jQuery(".wrapper").addClass("menu-blur");
      }, 300);
    }
    // Slide the mobile menu out.
    else {

      jQuery(".wrapper").removeClass("menu-blur");
      setTimeout(function() {
        jQuery(".wrapper").removeClass("menu-open");
      }, 100);

    }
  });



});









function update_header() {

  //current_top = jQuery(window).scrollTop();
  current_top = getScrollTop();

  if (current_top > 50) {
    jQuery('.header').addClass('colored');
  } else {
    jQuery('.header').removeClass('colored');
  }

}


jQuery(document).ready(function() {
  update_header();
  //jQuery(window).scroll($.throttle(65, update_header));
});






(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD module
    define(factory);
  } else if (typeof exports === 'object') {
    // CommonJS-like environment (i.e. Node)
    module.exports = factory();
  } else {
    // Browser global
    root.transformicons = factory();
  }
}(this || window, function() {

  // ####################
  // MODULE TRANSFORMICON
  // ####################
  'use strict';

  var
    tcon = {}, // static class
    _transformClass = 'tcon-transform',

    // const
    DEFAULT_EVENTS = {
      transform: ['click'],
      revert: ['click']
    };

  // ##############
  // private methods
  // ##############

  /**
   * Normalize a selector string, a single DOM element or an array of elements into an array of DOM elements.
   * @private
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements
   * @returns {array} Array of DOM elements
   */
  var getElementList = function(elements) {
    if (typeof elements === 'string') {
      return Array.prototype.slice.call(document.querySelectorAll(elements));
    } else if (typeof elements === 'undefined' || elements instanceof Array) {
      return elements;
    } else {
      return [elements];
    }
  };

  /**
   * Normalize a string with eventnames separated by spaces or an array of eventnames into an array of eventnames.
   * @private
   *
   * @param {(string|array)} elements - String with eventnames separated by spaces or array of eventnames
   * @returns {array} Array of eventnames
   */
  var getEventList = function(events) {
    if (typeof events === 'string') {
      return events.toLowerCase().split(' ');
    } else {
      return events;
    }
  };

  /**
   * Event handler for transform events.
   * @private
   *
   * @param {object} event - event object
   */
  var handleEvent = function(event) {
    tcon.toggle(event.currentTarget);
  };

  /**
   * Attach or remove transformicon events to one or more elements.
   * @private
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements to be toggled
   * @param {object} [events] - An Object containing one or more special event definitions
   * @param {boolean} [remove=false] - Defines wether the listeners should be added (default) or removed.
   */
  var setListeners = function(elements, events, remove) {
    var
      method = (remove ? 'remove' : 'add') + 'EventListener',
      elementList = getElementList(elements),
      currentElement = elementList.length,
      eventLists = {};

    // get events or use defaults
    for (var prop in DEFAULT_EVENTS) {
      eventLists[prop] = (events && events[prop]) ? getEventList(events[prop]) : DEFAULT_EVENTS[prop];
    }

    // add or remove all events for all occasions to all elements
    while (currentElement--) {
      for (var occasion in eventLists) {
        var currentEvent = eventLists[occasion].length;
        while (currentEvent--) {
          elementList[currentElement][method](eventLists[occasion][currentEvent], handleEvent);
        }
      }
    }
  };

  // ##############
  // public methods
  // ##############

  /**
   * Add transformicon behavior to one or more elements.
   * @public
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements to be toggled
   * @param {object} [events] - An Object containing one or more special event definitions
   * @param {(string|array)} [events.transform] - One or more events that trigger the transform. Can be an Array or string with events seperated by space.
   * @param {(string|array)} [events.revert] - One or more events that trigger the reversion. Can be an Array or string with events seperated by space.
   * @returns {transformicon} transformicon instance for chaining
   */
  tcon.add = function(elements, events) {
    setListeners(elements, events);
    return tcon;
  };

  /**
   * Remove transformicon behavior from one or more elements.
   * @public
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements to be toggled
   * @param {object} [events] - An Object containing one or more special event definitions
   * @param {(string|array)} [events.transform] - One or more events that trigger the transform. Can be an Array or string with events seperated by space.
   * @param {(string|array)} [events.revert] - One or more events that trigger the reversion. Can be an Array or string with events seperated by space.
   * @returns {transformicon} transformicon instance for chaining
   */
  tcon.remove = function(elements, events) {
    setListeners(elements, events, true);
    return tcon;
  };

  /**
   * Put one or more elements in the transformed state.
   * @public
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements to be transformed
   * @returns {transformicon} transformicon instance for chaining
   */
  tcon.transform = function(elements) {
    getElementList(elements).forEach(function(element) {
      element.classList.add(_transformClass);
    });
    return tcon;
  };

  /**
   * Revert one or more elements to the original state.
   * @public
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements to be reverted
   * @returns {transformicon} transformicon instance for chaining
   */
  tcon.revert = function(elements) {
    getElementList(elements).forEach(function(element) {
      element.classList.remove(_transformClass);
    });
    return tcon;
  };

  /**
   * Toggles one or more elements between transformed and original state.
   * @public
   *
   * @param {(string|element|array)} elements - Selector, DOM element or Array of DOM elements to be toggled
   * @returns {transformicon} transformicon instance for chaining
   */
  tcon.toggle = function(elements) {
    getElementList(elements).forEach(function(element) {
      tcon[element.classList.contains(_transformClass) ? 'revert' : 'transform'](element);
    });
    return tcon;
  };

  return tcon;
}));

jQuery(document).ready(function() {
  transformicons.add('.tcon');
});







(function($) {
  /*
   *  new_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function new_map($el) {

    // var
    var $markers = $el.find('.marker');


    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };


    // create map
    var map = new google.maps.Map($el[0], args);


    // add a markers reference
    map.markers = [];


    // add markers
    $markers.each(function() {

      add_marker($(this), map);

    });


    // center map
    center_map(map);


    // return
    return map;

  }

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    icon = {
      url: '/app/themes/lscny/assets/images/pin.png', // url
      scaledSize: new google.maps.Size(30, 44), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(15, 44) // anchor
    };

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      icon: icon,
      map: map
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open(map, marker);

      });
    }

  }

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function center_map(map) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {

      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);

    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(14);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }

  }

  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */
  // global var
  var map = null;

  $(document).ready(function() {

    $('.acf-map').each(function() {

      // create map
      map = new_map($(this));

    });

  });

})(jQuery);







jQuery(document).on('facetwp-refresh', function() {

});

jQuery(document).on('facetwp-loaded', function() {
  if (FWP.facets.team_search == '' && (FWP.facets.team == undefined || FWP.facets.team.length == 0)) {
    jQuery(".facet-reset").removeClass('inplace');
  } else {
    jQuery(".facet-reset").addClass('inplace');
  }


});